const am = angular.module('mtz-directives');

am.factory('googleAnalyticsSvc', [ () => {
  let svc = {};
  svc.callGoogleAnalytics = (type, pagePath, pageTitle, eventAction, eventParameters) => {
    // console.log('*******Google Analytics called...***********');
    // console.log('type = ', type);
    // console.log('pagePath = ', pagePath);
    // console.log('pageTitle = ', pageTitle);
    // console.log('eventAction = ', eventAction);
    // console.log('eventParameters = ', eventParameters);
    // console.log('*******************************************');

    if (type === 'pageview') {
      if (sessionStorage.username) {
        window.gtag('set', {'user_id': sessionStorage.controlNum});
      }

      window.gtag('config', window.propertyId, {
        'page_path': pagePath,
        'page_title': pageTitle + ' ' +  sessionStorage.userRZD
      });
    }
    else if (type === 'event') {
      //syntax: event, eventName, eventParameters
      let eventParametersRZDCategory = eventParameters.event_category;
      let eventParametersRZDLabel = eventParameters.event_label + ' ' + sessionStorage.userRZD;
      eventParameters = {
        'event_category' : eventParametersRZDCategory,
        'event_label' : eventParametersRZDLabel
      };
      window.gtag('event', eventAction, eventParameters);
    }
  };
  return svc;
}]);